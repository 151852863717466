import React from 'react'
import { Box, Button, Card } from '@mui/material'

export const ExampleElement: React.FC = () => {
    return (
        <Card data-testid="example-element">
            <div>Example Element</div>
            <Box sx={{ mt: 3 }}>
                <Button variant={'contained'}>Theme Button</Button>
            </Box>
        </Card>
    )
}
